<template>
  <div class="customer-contact">
    <div class="contact-form">
      <div class="form-item textarea-box">
        <div class="item-title required">群发名称</div>
        <van-field
          v-model="msgName"
          class="name-textarea"
          type="textarea"
          :autosize="{ minHeight: 12 }"
          maxlength="30"
          show-word-limit
        />
      </div>
      <div class="form-item" @click="add_staff">
        <div class="item-title required">所属员工</div>
        <div class="choose-btn">
          <div class="choose-text">{{ staffChosen }}</div>
          <img src="@/assets/images/arror_right.png" />
        </div>
      </div>
      <div class="form-item" @click="add_label">
        <div class="item-title">标签筛选</div>
        <div class="choose-btn">
          <div class="choose-text">{{ labelChosen }}</div>
          <img src="@/assets/images/arror_right.png" />
        </div>
      </div>
      <div class="form-info">
        <div class="item-title">消息内容</div>
        <van-field
          v-model="message"
          type="textarea"
          maxlength="1000"
          placeholder="请输入"
          show-word-limit
        />
      </div>
      <!-- <div
        class="form-item add-resource"
        v-if="
          (!previewUrl || loading) &&
          !formList['name'] &&
          !webSite['web_address']
        "
      >
        <div class="add" @click="file_sheet_show = true">
          <img src="@/assets/images/add_resource.png" />
          <div>添加素材</div>
        </div>
      </div> -->
      <!-- 选择图片 -->
      <div v-if="previewUrl && !loading" class="preview-img">
        <img :src="previewUrl" alt="" />
        <img
          class="img-close"
          @click="delFun"
          src="@/assets/images/img_close.png"
        />
      </div>
      <!-- 选择表单 -->
      <div class="form-container" v-if="formList['name']">
        <div class="form-title">{{ formList.name }}</div>
        <div class="form-content">
          <div class="content-info">{{ formList.description }}</div>
          <img class="content-img" :src="formList.background_url" />
        </div>
        <img
          class="img-close"
          @click="delForm"
          src="@/assets/images/img_close.png"
        />
      </div>
      <!-- 选择网址 -->
      <div class="form-container" v-if="webSite['web_address']">
        <div class="form-title">{{ webSite.web_title }}</div>
        <div class="form-content">
          <div class="content-info">{{ webSite.web_desc }}</div>
          <img class="content-img" :src="webSite.previewUrl" />
        </div>
        <img
          class="img-close"
          @click="delSite"
          src="@/assets/images/img_close.png"
        />
      </div>
    </div>
    <div class="contact-notes">
      <div>
        1.
        企业微信客户每个自然月最多接受来自同一企业微信管理员的4条群发消息，4条消息可在同一天发送；
      </div>
      <div>2. 若管理员在企微后台为客户创建客户群发也会占用群发机会。</div>
    </div>
    <van-action-sheet
      v-model="file_sheet_show"
      class="fixed fixed_position"
      :actions="file_types"
      cancel-text="取消"
      description="添加素材"
      close-on-click-action
      @select="onSelect"
    />
    <div class="footer-button fixed">
      <button class="cancel-btn" @click="cancelSendMsg">取消</button>
      <button
        v-permits.service
        class="send-btn"
        @click="sendMsg"
        :class="{ 'send-btn__disabled': sendDisabled }"
      >
        通知员工发送
      </button>
    </div>
    <input
      type="file"
      ref="upload"
      class="input-file-btn"
      @change="fileChange"
    />
  </div>
</template>
<script>
import uploadImgMix from "@/globalWidget/mixin/uploadImgMix.vue";
export default {
  name: "customerContact",
  components: {},
  mixins: [uploadImgMix],
  data() {
    return {
      message: "",
      msgName: "",
      file_sheet_show: false,
      file_types: [
        { name: "图片", type: 1 },
        { name: "网页", route: "addWebsite", type: 2 },
        { name: "表单", route: "addForm", type: 8 },
      ],
      staffList: [], // 员工id列表
      nameTextarea: "", // 群发消息名称
      resourceType: 0, // 素材类型 0-无
      labelChosen: "请选择",
      staffChosen: "",
      formList: {},
      webSite: {},
    };
  },
  activated() {
    if (this.$store.state.formList["name"]) {
      this.formList = this.$store.state.formList;
    } else {
      this.formList = {};
    }
    if (this.$store.state.tagName.length) {
      this.labelChosen = this.$store.state.tagName.join("，");
    } else {
      this.labelChosen = "";
    }
    if (this.$store.state.webpage["web_address"]) {
      this.webSite = this.$store.state.webpage;
    } else {
      this.webSite = {};
    }
    let staff = this.$store.state.userList;
    let staffNameList = [];
    let list = [];
    if (!staff || staff.length == 0) {
      this.staffChosen = "请选择";
    } else {
      staff.forEach((e) => {
        let staffItem = {};
        let item = e.split("_");
        staffItem.user_id = item[0];
        staffItem.name = item[1];
        list.push(staffItem);
        staffNameList.push(item[1]);
      });
      this.staffList = list;
      this.staffChosen = staffNameList.join("、");
    }
  },
  computed: {
    hasResource() {
      return (
        this.previewUrl || this.formList["name"] || this.webSite["web_address"]
      );
    },
    sendDisabled() {
      return (
        !this.msgName ||
        !this.staffList.length ||
        (!this.message && !this.hasResource)
      );
    },
  },
  methods: {
    add_staff() {
      this.$router.push({
        name: "addStaff",
      });
    },
    add_label() {
      this.$router.push({
        name: "addLabel",
      });
    },
    onSelect(item) {
      this.resourceType = item.type;
      if (item.name === "图片") {
        this.$refs.upload.click();
      }
      if (item.route) {
        this.$router.push({
          name: item.route,
        });
      }
    },
    cancelSendMsg() {
      this.$router.go(-1);
    },
    checkMsg() {
      if (!this.msgName) {
        this.$toast("请输入消息名称");
      } else if (!this.staffList || this.staffList.length === 0) {
        this.$toast("请选择使用员工");
      } else if (!this.message && !this.hasResource) {
        this.$toast("请输入消息内容");
      } else {
        this.sendDisabled = false;
        return true;
      }
      return false;
    },
    sendMsg() {
      if (!this.checkMsg()) {
        return;
      }
      let params = {
        msg_name: this.msgName,
        send_object: 1,
        use_customers: this.staffList,
        content_welcome: this.message || "",
        material_type: this.resourceType,
        send_type: 2, // 2-立即发送
      };
      if (this.resourceType === 1) {
        params.url_material = this.previewUrl;
      }
      if (this.resourceType === 8) {
        params = {
          ...params,
          web_desc: this.formList.description,
          web_img_url: this.formList.background_url,
          web_title: this.formList.name,
          web_url: this.formList.post_url,
        };
      }
      if (this.resourceType === 2) {
        params = {
          ...params,
          web_desc: this.webSite.web_desc,
          web_img_url: this.webSite.previewUrl,
          web_title: this.webSite.web_title,
          web_url: this.webSite.web_address,
        };
      }
      if (this.$store.state.tagList.length) {
        params.include_tag_ids = this.$store.state.tagList.join(",");
      }
      this.$ajax("edit_group_news", params).then((res) => {
        if (res.data && res.data.code === 0) {
          this.$router.push({ name: "createSuccess" });
        } else {
          this.$toast(res.data.msg);
        }
      }).catch((err)=>{
        this.$toast(err.msg||"创建群发消息失败");
      });
    },
    //删除表单
    delForm() {
      this.formList = {};
      this.$store.commit("setFormList", {});
    },
    //删除网址
    delSite() {
      this.webSite = {};
      this.$store.commit("setWebpageData", {});
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "home") {
      // 离开页面 重置数据
      this.$store.dispatch("resetData");
      Object.assign(this.$data, this.$options.data());
    }
    next();
  },
};
</script>
<style>
::selection {
  background: #308DFC;
  color: white;
}
</style>
<style lang="scss" scoped>
.customer-contact {
  padding: 0.32rem 0.32rem 1.2rem;
  background: #f5f6f9;
  div[contenteditable]:empty:not(:focus):before {
    content: attr(placeholder);
    color: #aaa;
    display: flex;
    justify-content: flex-end;
  }
  .contact-form {
    background: #ffffff;
    border-radius: 0.16rem;
    padding: 0.04rem 0.32rem 0.32rem;
    .form-item {
      display: flex;
      height: 0.96rem;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      .choose-btn {
        display: flex;
        align-items: center;
        overflow: hidden;
      }
      .choose-text {
        font-size: 0.28rem;
        font-weight: 400;
        color: #333333;
        line-height: 0.4rem;
        // text-align: right;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      img {
        height: 0.32rem;
        width: 0.32rem;
      }
    }
    .add-resource {
      border: none;
      height: auto;
      padding-top: 0.32rem;
    }
    .add {
      display: flex;
      font-size: 0.28rem;
      font-weight: 400;
      color: #666666;
      line-height: 0.4rem;
      img {
        width: 0.4rem;
        height: 0.4rem;
        margin-right: 0.2rem;
      }
    }
    .textarea-box {
      height: auto;
      align-items: flex-start;
      padding: 0.28rem 0;
    }
    .item-title {
      width: 1.68rem;
      flex-shrink: 0;
      font-size: 0.28rem;
      font-weight: 400;
      color: #666666;
      line-height: 0.4rem;
    }
    .required::after {
      content: "*";
      font-size: 0.28rem;
      font-weight: 400;
      color: #ff4747;
      line-height: 0.4rem;
      margin-left: 0.08rem;
    }
    .form-info {
      padding-top: 0.32rem;
    }
  }
  .footer-button {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 1.12rem;
    background: #ffffff;
    box-shadow: 0rem 0rem 0.16rem 0rem rgba(0, 0, 0, 0.08);
    button {
      border: none;
      outline: none;
      width: 3.28rem;
      height: 0.8rem;
      border-radius: 0.4rem;
      font-size: 0.32rem;
      font-weight: 500;
      line-height: 0.48rem;
    }
    .cancel-btn {
      border: 1px solid #cccccc;
      background-color: #ffffff;
      color: #333333;
    }
    .send-btn {
      background: #105cfb;
      color: #ffffff;
    }
    .send-btn__disabled {
      background: #f5f5f5;
      color: #b2b2b2;
    }
  }
  .input-file-btn {
    display: none;
  }
  .preview-img {
    position: relative;
    padding: 0.32rem 0;
    img {
      width: 0.96rem;
      // height: 1.92rem;
    }
    .img-close {
      position: absolute;
      width: 0.32rem;
      height: 0.32rem;
      top: 0.16rem;
      left: 0.8rem;
    }
  }
  .form-container {
    position: relative;
    width: 4.6rem;
    padding: 0.24rem;
    margin-top: 0.32rem;
    background: #ffffff;
    border-radius: 0.16rem;
    border: 1px solid #cccccc;
    .img-close {
      position: absolute;
      width: 0.32rem;
      height: 0.32rem;
      top: -0.16rem;
      right: -0.16rem;
    }
    .form-title {
      font-size: 0.24rem;
      font-weight: 400;
      color: #666666;
      line-height: 0.36rem;
    }
    .form-content {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      margin-top: 0.12rem;
    }
    .content-info {
      flex: 1;
      font-size: 0.2rem;
      font-weight: 400;
      color: #666666;
      line-height: 0.32rem;
    }
    .content-img {
      width: 0.64rem;
      height: 0.64rem;
      border-radius: 0.08rem;
      border: 1px solid #333333;
      margin-left: 0.16rem;
    }
  }
  .contact-notes {
    margin-top: 0.3rem;
    font-size: 0.24rem;
    font-weight: 400;
    color: #999999;
    line-height: 0.36rem;
  }
  .fixed_position {
    right: 0;
  }
}
</style>
<style lang="scss">
.form-info {
  .van-field--min-height .van-field__control {
    min-height: 2.9rem;
  }
  .van-cell {
    margin-top: 0.24rem;
    padding: 0;
    background-color: #f5f5f5;
    border-radius: 0.16rem;
  }
  .van-field__value {
    padding: 0.2rem;
  }
}
.form-item {
  .van-cell {
    padding: 0;
  }
}
.customer-contact {
  .van-overlay {
    max-width: 480px;
    right: 0;
    margin: 0 auto;
  }
}
</style>
